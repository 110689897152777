import React from "react";

export default function WhatsOn() {
  return (
    <section id="content">
      <div
        className=""
        style={{
          background: `linear-gradient(
            to top,
            rgb(213,213,213) 30%,
            white
        )`,
        }}
      >
        <div className="container mw-lg py-lg-5">
          <div className="text-center mb-5 mb-lg-6">
            <h2
              style={{ color: "rgb(150, 120, 157)" }}
              className="display-5 fw-bold font-secondary"
            >
              What's on
            </h2>
          </div>

          <div className="clear"></div>

          <div className="row grid-border justify-content-center mt-3">
            <div className="col-lg-4 col-padding py-lg-0">
              {/* <i className="fa-solid fs-2 fa-comment mb-3"></i> */}
              <h3 className="mb-1 fw-semibold">Monthly Investment Carousel</h3>
              <p className="mb-5">
                Each participating LP shares 1 investment they recently made and
                why
              </p>
            </div>

            <div className="col-lg-4 col-padding py-lg-0">
              {/* <i className="fa-solid fs-2 fa-life-ring mb-3"></i> */}
              <h3 className="mb-1 fw-semibold">Mentorship Program</h3>
              <p className="mb-5">
                Help next generation female GPs and LPs develop into stars of
                tomorrow
              </p>
            </div>

            <div className="col-lg-4 col-padding py-lg-0">
              {/* <i className="fa-solid fs-2 fa-life-ring mb-3"></i> */}
              <h3 className="mb-1 fw-semibold">...And much more</h3>
              <p className="mb-5">
                Engage, connect, and learn with a vibrant community of
                investors.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
