import { PopupButton } from "@typeform/embed-react";
import React from "react";

export default function CTA() {
  return (
    <section id="content" style={{ background: "rgb(213,213,213)" }}>
      <div className="content-wrap">
        <div
          className="section text-center"
          style={{ background: "rgb(213,213,213)" }}
        >
          <div className="container py-5">
            <div className="promo-users">
              <img
                src="demos/forum/images/users/1.jpg"
                alt=".."
                className="square square-lg rounded-circle"
              />
              {/* <img
                src="demos/forum/images/users/2.jpg"
                alt=".."
                className="square square-md rounded-circle"
              /> */}
              {/* <img
                src="demos/forum/images/users/3.jpg"
                alt=".."
                className="square square-md rounded-circle"
              /> */}
              <img
                src="images/woman1.png"
                alt=".."
                className="square square-lg rounded-circle"
              />
              <img
                src="images/woman2.png"
                alt=".."
                className="square square-lg rounded-circle"
              />
            </div>
            <div className="row justify-content-center">
              <div className="col-md-10">
                <p className="text-uppercase fw-semibold mb-4 ls-1 font-primary text-black">
                  Get started
                </p>
                <h1
                  className="display-4 fw-normal"
                  style={{ letterSpacing: "-1px;" }}
                >
                  Join a stellar group of pioneering LPs
                </h1>
                {/* <p className="op-07 mw-xs mx-auto mb-5">
                  We'll take some basic details to get to know you, then reach
                  out and...
                </p> */}
                <PopupButton
                  id="hqnsYQyd"
                  className="button button-xlarge rounded-pill m-0 mt-2 px-5"
                  style={{
                    padding: "0.75rem 2rem",
                    background: "rgb(150, 120, 157)",
                  }}
                >
                  Get Involved
                </PopupButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
