import React, { useEffect } from "react";
import { initRevSlider } from "./init";

export default function RevSlider() {
  useEffect(() => {
    initRevSlider();
  }, []);

  return (
    <section
      id="slider"
      className="slider-element revslider-wrap h-auto include-header"
    >
      <div
        className="rev_slider_wrapper"
        style={{ background: "#aaa", padding: 0 }}
      >
        <div
          id="rev_slider_52_1_wrapper"
          className="rev_slider_wrapper fullscreen-container"
          data-alias="mountain-parallax-header"
          data-source="gallery"
          style={{ background: "rgba(255,255,255,0)", padding: 0 }}
        >
          <div
            id="rev_slider_73_1"
            className="rev_slider fullscreenbanner"
            style={{ display: "none" }}
            data-version="5.4.5"
          >
            <ul>
              <li
                data-index="rs-167"
                data-transition="brightnesscross"
                data-slotamount="default"
                data-hideafterloop="0"
                data-hideslideonmobile="off"
                data-easein="default"
                data-easeout="default"
                data-masterspeed="default"
                data-thumb="include/rs-plugin/demos/assets/images/100x50_exp_bg1.jpg"
                data-rotate="0"
                data-saveperformance="off"
                data-title="Slide"
                data-param1=""
                data-param2=""
                data-param3=""
                data-param4=""
                data-param5=""
                data-param6=""
                data-param7=""
                data-param8=""
                data-param9=""
                data-param10=""
                data-description=""
              >
                <img
                  src="include/rs-plugin/demos/assets/images/dummy.png"
                  alt="Image"
                  data-lazyload="include/rs-plugin/demos/assets/images/exp_bg1.jpg"
                  data-bgposition="center center"
                  data-bgfit="cover"
                  data-bgrepeat="no-repeat"
                  data-bgparallax="6"
                  className="rev-slidebg"
                  data-no-retina
                />

                <div
                  id="rrzm_167"
                  className="rev_row_zone rev_row_zone_middle"
                  style={{ zIndex: 11 }}
                >
                  <div
                    className="tp-caption   rs-parallaxlevel-4"
                    id="slide-167-layer-7"
                    data-x="['left','left','left','left']"
                    data-hoffset="['100','100','100','100']"
                    data-y="['middle','middle','middle','middle']"
                    data-voffset="['0','0','0','0']"
                    data-width="none"
                    data-height="none"
                    data-whitespace="nowrap"
                    data-type="row"
                    data-columnbreak="2"
                    data-responsive_offset="on"
                    data-responsive="off"
                    data-frames='[{"delay":10,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                    data-margintop="[0,0,0,0]"
                    data-marginright="[0,0,0,0]"
                    data-marginbottom="[0,0,0,0]"
                    data-marginleft="[0,0,0,0]"
                    data-textAlign="['inherit','inherit','inherit','inherit']"
                    data-paddingtop="[0,0,0,0]"
                    data-paddingright="[50,50,30,30]"
                    data-paddingbottom="[0,0,0,0]"
                    data-paddingleft="[50,50,30,30]"
                    style={{
                      zIndex: 5,
                      whiteSpace: "nowrap",
                      fontSize: "20px",
                      lineHeight: "22px",
                      fontWeight: 400,
                      color: "#ffffff",
                      letterSpacing: "0px",
                    }}
                  >
                    <div
                      className="tp-caption  "
                      id="slide-167-layer-8"
                      data-x="['left','left','left','left']"
                      data-hoffset="['100','100','100','100']"
                      data-y="['top','top','top','top']"
                      data-voffset="['100','100','100','100']"
                      data-width="none"
                      data-height="none"
                      data-whitespace="nowrap"
                      data-type="column"
                      data-responsive_offset="on"
                      data-responsive="off"
                      data-frames='[{"delay":"+0","speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                      data-columnwidth="50%"
                      data-verticalalign="top"
                      data-margintop="[0,0,0,0]"
                      data-marginright="[0,0,0,0]"
                      data-marginbottom="[0,0,0,0]"
                      data-marginleft="[0,0,0,0]"
                      data-textAlign="['inherit','inherit','inherit','inherit']"
                      data-paddingtop="[0,0,0,0]"
                      data-paddingright="[20,20,0,0]"
                      data-paddingbottom="[0,0,0,0]"
                      data-paddingleft="[0,0,0,0]"
                      style={{ zIndex: 6, width: "100%" }}
                    >
                      <div
                        className="tp-caption   tp-resizeme"
                        id="slide-167-layer-2"
                        data-x="['left','left','left','left']"
                        data-hoffset="['0','53','53','42']"
                        data-y="['top','top','top','top']"
                        data-voffset="['0','123','123','122']"
                        data-letterspacing="['5','5','5','3']"
                        data-width="none"
                        data-height="none"
                        data-whitespace="['normal','nowrap','nowrap','nowrap']"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames='[{"delay":"+990","speed":2000,"frame":"0","from":"opacity:0;","color":"#e5452b","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"frame":"999","color":"transparent","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                        data-margintop="[0,0,0,0]"
                        data-marginright="[0,0,0,0]"
                        data-marginbottom="[24,28,21,21]"
                        data-marginleft="[0,0,0,0]"
                        data-textAlign="['inherit','inherit','inherit','inherit']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: 7,
                          whiteSpace: "normal",
                          fontSize: "17px",
                          lineHeight: "24px",
                          fontWeight: 400,
                          color: "#000000",
                          letterSpacing: "5px",
                          display: "block",
                          fontFamily: "Oswald",
                          textTransform: "uppercase",
                        }}
                      >
                        Lady LP Alliance
                      </div>

                      <div
                        className="tp-caption   tp-resizeme"
                        id="slide-167-layer-1"
                        data-x="['left','left','left','left']"
                        data-hoffset="['0','50','50','40']"
                        data-y="['top','top','top','top']"
                        data-voffset="['0','170','170','167']"
                        data-fontsize="['60','50','40','40']"
                        data-lineheight="['90','75','60','60']"
                        data-letterspacing="['15','15','10','7']"
                        data-width="['100%','100%','561','401']"
                        data-height="none"
                        data-whitespace="normal"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames='[{"delay":"+290","split":"chars","splitdelay":0.05,"speed":2000,"split_direction":"forward","frame":"0","from":"opacity:0;","color":"#000000","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"frame":"999","color":"transparent","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                        data-margintop="[0,0,0,0]"
                        data-marginright="[0,0,0,0]"
                        data-marginbottom="[30,31,26,26]"
                        data-marginleft="[0,0,0,0]"
                        data-textAlign="['inherit','inherit','inherit','inherit']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: 8,
                          minWidth: "100%",
                          maxWidth: "100%",
                          whiteSpace: "normal",
                          fontSize: "60px",
                          lineHeight: "90px",
                          fontWeight: 400,
                          color: "rgb(161, 139, 116)",
                          letterSpacing: "15px",
                          display: "block",
                          fontFamily: "Oswald",
                          textTransform: "uppercase",
                        }}
                      >
                        Shaping the future
                      </div>

                      <div
                        className="tp-caption"
                        id="slide-365-layer-11"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']"
                        // data-voffset="['-200','-160','-120','-180']"

                        // data-y="['bottom','bottom','bottom','bottom']"
                        data-voffset="['0','0','0','0']"
                        data-fontsize="['45','40','30','30']"
                        data-lineheight="['150','100','90','60']"
                        data-letterspacing="['-2','-1','-1','0']"
                        data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames='[{"delay":2000,"speed":2000,"frame":"0","from":"y:50px;opacity:0;fb:10px;","to":"o:1;fb:0;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                        data-textAlign="['inherit','inherit','inherit','inherit']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: 14,
                          whiteSpace: "nowrap",
                          fontSize: "60px",
                          lineHeight: "150px",
                          fontWeight: 700,
                          color: "rgb(150,120,157)",
                          letterSpacing: "-5px",
                          top: -30,
                          fontFamily: "Poppins",
                          textShadow: "0px 15px 30px rgba(150, 150, 150, 0.25)",
                        }}
                      >
                        with today's women leaders
                      </div>

                      <div
                        className="tp-caption tp-shape tp-shapewrapper  tp-resizeme"
                        id="slide-167-layer-3"
                        data-x="['left','left','left','left']"
                        data-hoffset="['0','53','53','42']"
                        data-y="['top','top','top','top']"
                        data-voffset="['0','440','498','373']"
                        data-width="50"
                        data-height="1"
                        data-whitespace="['normal','nowrap','nowrap','nowrap']"
                        data-type="shape"
                        data-responsive_offset="on"
                        data-frames='[{"delay":"+1490","speed":2000,"frame":"0","from":"sX:0;opacity:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                        data-margintop="[0,0,0,0]"
                        data-marginright="[0,0,0,0]"
                        data-marginbottom="[0,0,0,0]"
                        data-marginleft="[0,0,0,0]"
                        data-textAlign="['inherit','inherit','inherit','inherit']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: 9,
                          display: "block",
                          backgroundColor: "rgb(0, 0, 0)",
                        }}
                      >
                        {" "}
                      </div>
                    </div>

                    <div
                      className="tp-caption  "
                      id="slide-167-layer-9"
                      data-x="['left','left','left','left']"
                      data-hoffset="['100','100','100','100']"
                      data-y="['top','top','top','top']"
                      data-voffset="['100','100','100','100']"
                      data-width="none"
                      data-height="none"
                      data-whitespace="nowrap"
                      data-type="column"
                      data-responsive_offset="on"
                      data-responsive="off"
                      data-frames='[{"delay":"+0","speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                      data-columnwidth="50%"
                      data-verticalalign="top"
                      data-margintop="[0,0,0,0]"
                      data-marginright="[0,0,0,0]"
                      data-marginbottom="[0,0,0,0]"
                      data-marginleft="[0,0,0,0]"
                      data-textAlign="['inherit','inherit','inherit','inherit']"
                      data-paddingtop="[0,0,0,0]"
                      data-paddingright="[0,0,0,0]"
                      data-paddingbottom="[0,0,0,0]"
                      data-paddingleft="[0,0,0,0]"
                      style={{ zIndex: 10, width: "100%" }}
                    >
                      {" "}
                    </div>
                  </div>

                  <div
                    className="tp-caption   rs-parallaxlevel-5"
                    id="slide-167-layer-10"
                    data-x="['left','left','left','left']"
                    data-hoffset="['100','100','100','100']"
                    data-y="['middle','middle','middle','middle']"
                    data-voffset="['0','0','0','0']"
                    data-width="none"
                    data-height="none"
                    data-whitespace="nowrap"
                    data-type="row"
                    data-columnbreak="2"
                    data-responsive_offset="on"
                    data-responsive="off"
                    data-frames='[{"delay":10,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                    data-margintop="[0,0,0,0]"
                    data-marginright="[0,0,0,0]"
                    data-marginbottom="[0,0,0,0]"
                    data-marginleft="[0,0,0,0]"
                    data-textAlign="['inherit','inherit','inherit','inherit']"
                    data-paddingtop="[0,0,0,0]"
                    data-paddingright="[50,50,30,30]"
                    data-paddingbottom="[0,0,0,0]"
                    data-paddingleft="[50,50,30,30]"
                    style={{
                      zIndex: 11,
                      whiteSpace: "nowrap",
                      fontSize: "20px",
                      lineHeight: "22px",
                      fontWeight: 400,
                      color: "#ffffff",
                      letterSpacing: "0px",
                    }}
                  >
                    <div
                      className="tp-caption  "
                      id="slide-167-layer-11"
                      data-x="['left','left','left','left']"
                      data-hoffset="['100','100','100','100']"
                      data-y="['top','top','top','top']"
                      data-voffset="['100','100','100','100']"
                      data-width="none"
                      data-height="none"
                      data-whitespace="nowrap"
                      data-type="column"
                      data-responsive_offset="on"
                      data-responsive="off"
                      data-frames='[{"delay":"+0","speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                      data-columnwidth="50%"
                      data-verticalalign="top"
                      data-margintop="[0,0,0,0]"
                      data-marginright="[0,0,0,0]"
                      data-marginbottom="[0,0,0,0]"
                      data-marginleft="[0,0,0,0]"
                      data-textAlign="['inherit','inherit','inherit','inherit']"
                      data-paddingtop="[0,0,0,0]"
                      data-paddingright="[0,0,0,0]"
                      data-paddingbottom="[0,0,0,0]"
                      data-paddingleft="[0,0,0,0]"
                      style={{ zIndex: 12, width: "100%" }}
                    >
                      {" "}
                    </div>

                    <div
                      className="tp-caption  "
                      id="slide-167-layer-12"
                      data-x="['left','left','left','left']"
                      data-hoffset="['100','100','100','100']"
                      data-y="['top','top','top','top']"
                      data-voffset="['100','100','100','100']"
                      data-width="none"
                      data-height="none"
                      data-whitespace="nowrap"
                      data-type="column"
                      data-responsive_offset="on"
                      data-responsive="off"
                      data-frames='[{"delay":"+0","speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                      data-columnwidth="50%"
                      data-verticalalign="top"
                      data-margintop="[0,0,0,0]"
                      data-marginright="[0,0,0,0]"
                      data-marginbottom="[0,0,0,0]"
                      data-marginleft="[0,0,0,0]"
                      data-textAlign="['inherit','inherit','inherit','inherit']"
                      data-paddingtop="[0,0,0,0]"
                      data-paddingright="[0,0,0,0]"
                      data-paddingbottom="[0,0,0,0]"
                      data-paddingleft="[20,20,0,0]"
                      style={{ zIndex: 13, width: "100%" }}
                    >
                      <a
                        className="tp-caption rev-btn  tp-resizeme"
                        // href="#about"
                        data-scrollto="#about"
                        id="slide-167-layer-5"
                        data-x="['left','left','left','left']"
                        data-hoffset="['0','540','53','42']"
                        data-y="['top','top','bottom','bottom']"
                        data-voffset="['0','600','53','42']"
                        data-width="none"
                        data-height="none"
                        data-whitespace="['normal','nowrap','nowrap','nowrap']"
                        data-type="button"
                        data-actions=""
                        data-responsive_offset="on"
                        data-frames='[{"delay":"+1990","speed":2000,"frame":"0","from":"opacity:0;fbr:0%;","to":"o:1;fbr:100;","ease":"Power4.easeInOut"},{"delay":"wait","speed":2000,"frame":"999","to":"opacity:0;fbr:0%;","ease":"Power4.easeInOut"},{"frame":"hover","speed":"500","ease":"Power3.easeInOut","to":"o:1;rX:0;rY:0;rZ:0;z:0;fbr:90%;","style":"c:rgba(255,255,255,1);bs:solid;bw:0 0 0 0;"}]'
                        data-margintop="[0,0,0,0]"
                        data-marginright="[0,0,0,0]"
                        data-marginbottom="[0,0,0,0]"
                        data-marginleft="[0,0,0,0]"
                        data-textAlign="['inherit','inherit','inherit','inherit']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[50,50,50,50]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[50,50,50,50]"
                        style={{
                          zIndex: 15,
                          whiteSpace: "normal",
                          fontSize: "15px",
                          lineHeight: "60px",
                          fontWeight: 400,
                          color: "rgba(255, 255, 255, 1)",
                          letterSpacing: "5px",
                          display: "inline-block",
                          fontFamily: "Oswald",
                          textTransform: "uppercase",
                          backgroundColor: "rgb(129, 140, 140)",
                          borderColor: "rgba(0, 0, 0, 1)",
                          outline: "none",
                          boxShadow: "none",
                          boxSizing: "border-box",
                          cursor: "pointer",
                          textDecoration: "none",
                        }}
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div
              className="tp-bannertimer tp-bottom"
              style={{ height: 10, background: "rgb(229,69,43)" }}
            ></div>
          </div>
        </div>
      </div>
    </section>
  );
}
