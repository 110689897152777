import React from "react";

export default function Hero() {
  return (
    <section
      id="about"
      className="slider-element block-hero-26 min-vh-100 py-5 d-flex align-items-center"
    >
      <div className="container">
        <div className="row align-items-center justify-content-between g-5">
          <div className="col-xl-5">
            <h2
              className="display-3 fw-bold font-secondary"
              style={{ color: "rgb(150, 120, 157)" }}
            >
              A network of investors and enablers
            </h2>
            {/* <p className="op-08 mb-5">
              We envision a future where capital is deployed intelligently for
              human good
            </p> */}
          </div>

          <div className="col-xl-7 align-items-end">
            <div className="row col-mb-30">
              <div className="col-xxl-6 col-sm-5">
                <img
                  src="images/pexels-mart-production-7222940.jpg"
                  alt="Image"
                />
              </div>
              <div className="col-xxl-5 col-sm-6">
                <h6 className="text-uppercase ls-2 fw-medium">We are</h6>
                <div>
                  <h2 className="mb-2 font-secondary fw-semibold">Global</h2>
                  <p className="mb-5 op-07 text-smaller">
                    Our network spans all continents, embracing members from
                    diverse global backgrounds.
                  </p>
                </div>

                <div>
                  <h2 className="mb-2 font-secondary fw-semibold">Inclusive</h2>
                  <p className="mb-5 op-07 text-smaller">
                    Our community is open to all genders, focusing on universal
                    topics that matter in investing and life.
                  </p>
                </div>

                <div>
                  <h2 className="mb-2 font-secondary fw-semibold ">
                    Nurturing
                  </h2>
                  <p className="mb-0 op-07 text-smaller">
                    We empower aspiring female entrepreneurs and fund managers
                    to make significant impacts in their fields.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
