import React, { useEffect, useRef } from "react";
import CTA from "../components/CTA";
import RevSlider from "../components/RevSlider";
import WeAre from "../components/WeAre";
import WhatsOn from "../components/WhatsOn";

export default function Root() {
  useFunctionRun();
  return (
    <div id="wrapper">
      {/* <Header transparent /> */}
      <RevSlider />
      <WeAre />
      <WhatsOn />
      <CTA />
    </div>
  );
}

export function useFunctionRun() {
  var isRun = useRef(false);

  useEffect(() => {
    if (!isRun.current) {
      (window as any).SEMICOLON.Hack.init();
      isRun.current = true;
    }
  }, []);
  return null;
}
