// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyCk1M-RNqgZdczBSI4FHEdgWKkziFQ1xq8",
  authDomain: "deals-7301a.firebaseapp.com",
  projectId: "deals-7301a",
  storageBucket: "deals-7301a.appspot.com",
  messagingSenderId: "282959735247",
  appId: "1:282959735247:web:c0fbddc2ba6d2eea580aba",
  measurementId: "G-2CDGCD0HSY",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
